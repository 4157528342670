.product {
    color: #333;
    margin-bottom: 96px;
}

.product .product-links {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
}

.product .product-links .product-link:nth-child(3n),
.product .product-links .product-link:nth-child(3n-1) {
    background-color: var(--up-light);
}

.product .product-links .product-link {
    width: 50%;
    position: relative;
    overflow: hidden;
}

.product .product-links .product-link .product-main {
    padding: 80px 50px;
    position: relative;
}

.product .product-links .product-link .product-main .btn-more {
    position: absolute;
    top: 300px;
    left: 50px;
    z-index: 900;
    background-color: #fff;
}

.product .product-links .product-link:nth-child(3n) .product-main .btn-more,
.product .product-links .product-link:nth-child(3n-1) .product-main .btn-more {
    background-color: var(--up-light);
}

.product .product-links .product-link .product-main .product-img {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    transition: all 0.2s linear;
}

.product .product-links .product-link:nth-child(3n) .product-main .product-img,
.product .product-links .product-link:nth-child(3n-1) .product-main .product-img {
    background-color: var(--up-light);
}

.product .product-links .product-link:hover {
    cursor: pointer;
}

.product .product-links .product-link:hover .product-main .product-img {
    opacity: 1;
}

.product .product-links .product-link .product-main .product-title {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 190px;
}

.product .product-links .product-link .product-main .product-subTitle {
    font-size: 24px;
    opacity: 0.4;
    display: block;
    margin-bottom: 16px;
}

/*关于*/
.about-company {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 96px;
    background-color: var(--up-light)
}

.about-company .company-img {
    width: 40%;
}

.about-company .company-main {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}




.about-company .company-main .company-wrap {
    padding: 32px 15% 32px 5%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.about-company .company-main .company-content {
    font-size: 28px;
    line-height: 1.8;
    flex: 1;
    display: flex;
    align-items: center;

}

.about-company .company-main .company-wrap .company-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: .8em;

}

.news-section {
    margin-bottom: 32px;
}

.news-section .news-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.news-section .news-wrap .news-head {
    margin-bottom: 2em;
    text-align: center;
}

.news-section .news-wrap .news-contents {
    display: flex;
}

.news-section .news-wrap .news-contents .news-item-wrap {
    width: 33.333%;
}

.news-section .news-wrap .news-contents .news-item-wrap .news-item {
    margin: 0 12px;
    background-color: var(--up-light);
}
.news-section .news-wrap .news-contents .news-item-wrap .news-item .news-img-box{
    position: relative;
    padding-bottom: 40%;
    overflow: hidden;
}
.news-section .news-wrap .news-contents .news-item-wrap .news-item .news-img-box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}
.news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info {
    padding: 24px;
}

.news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info .info-title .news-subTitle {
    display: block;
    width: 100%;
    font-size: 14px;
    color: var(--up-orange);
    margin-bottom: 14px;
    line-height: 1;
}

.news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info .info-title .news-title {
    font-size: 24px;
    display: block;
    line-height: 32px;
    color: var(--up-dark);
    font-weight: bold;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 98px;
    width: 100%;
    margin-bottom: 30px;
    word-break: break-all;
}

.news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info .news-footer {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info .news-footer .news-date {
    font-weight: bolder;
    color: var(--bs-gray);
}

.news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info .news-footer .btn-more {
    padding: 5px 20px;
}



@media screen and (max-width:1920px){
    .about-company .company-main .company-content{
        font-size: 20px;
    }
}

@media screen and (max-width:1400px) {
    .about-company .company-main .company-content {
        font-size: 16px;
    }
    .product .product-links .product-link .product-main .product-img {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width:1200px) {
    .product{
        margin-bottom: 48px;
    }
    .about-company {
        margin-bottom:48px;
    }
    .product .product-links .product-link .product-main .product-subTitle {
        font-size: 20px;
    }

    .product .product-links .product-link .product-main .product-title {
        font-size: 36px;
    }


    .about-company {
        flex-direction: column;
    }

    .about-company .company-img img {
        height: 300px;
        -o-object-fit: cover;
           object-fit: cover;
        width: 100%;
    }

    .about-company .company-main {
        width: auto;
    }

    .about-company .company-img {
        width: auto;
    }

    .about-company .company-main .company-wrap {
        padding: 32px 8.5% 32px 8.5%;
    }


}
@media screen and (max-width:1024px) {

    .news-section .news-wrap .news-contents{
        flex-direction: column;
    }

    .news-section .news-wrap .news-contents .news-item-wrap{
        width: 100%;
    }
    .news-section .news-wrap .news-contents .news-item-wrap .news-item{
        margin: 0 0 32px 0;
    }
    .news-section .news-wrap .news-contents .news-item-wrap:last-child .news-item{
        margin: 0;
    }
}
@media screen and (max-width:992px)  {
    main{
        margin-top: 0px;
    }
}
@media screen and (max-width:768px) {
    .product .product-links .product-link {
        width: 100%;
    }

    .product .product-links .product-link .product-main .product-img {
        top: 10px;
    }


    .product .product-links .product-link:nth-child(3n){
        background-color: #fff;
    }

    .product .product-links .product-link:nth-child(3n) .product-main .btn-more{
        background-color: #fff;
    }

    .product .product-links .product-link:nth-child(3n) .product-main .product-img{
        background-color: #fff;
    }


    .product .product-links .product-link:nth-child(2n){
        background-color: var(--up-light);
    }

    .product .product-links .product-link:nth-child(2n) .product-main .btn-more{
        background-color: var(--up-light);
    }

    .product .product-links .product-link:nth-child(2n) .product-main .product-img{
        background-color: var(--up-light);
    }

}

@media screen and (max-width:425px) {
    .product .product-links .product-link .product-main {
        padding: 40px 25px;
    }

    .product .product-links .product-link .product-main .product-img {
        top: 0;
    }

    .product .product-links .product-link .product-main .product-title {
        margin-bottom: 100px;
    }

    .product .product-links .product-link .product-main .btn-more {
        top: 150px;
        left: 25px;
    }

    .product .product-links .product-link .product-main .product-subTitle {
        font-size: 16px;
    }

    .product .product-links .product-link .product-main .product-title {
        font-size: 24px;
    }

    .about-company .company-main .company-content {
        font-size: 14px;
    }
    .news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info .info-title .news-title{
        font-size: 20px;
        height:68px;
        margin-bottom:20px;
    }
}
@media screen and (max-width:375px) {
    .news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info .info-title .news-subTitle{
        font-size: 12px;
    }
    .news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info .info-title .news-title{
        font-size: 16px;
        margin-bottom:10px;
    }
    .news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info{
        padding: 14px;
    }
}
@media screen and (max-width:320px) {
    .news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info .info-title .news-subTitle{
        font-size: 12px;
    }
    .news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info .info-title .news-title{
        font-size: 14px;
        height:auto;
    }
    .news-section .news-wrap .news-contents .news-item-wrap .news-item .news-info{
        padding: 10px;
    }
}














/* service */
.service-box {
    margin-bottom: 64px;
}

.service-box .big-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 5%;
}

.service-box .big-wrap .big-text {
    display: flex;
}

.service-box .big-wrap .big-text i {
    font-size: 48px;
    color: var(--up-grey);
}

.service-box .big-wrap .big-text p {
    margin: 48px;
    font-size: 24px;
    line-height: 1.5;
}

.service-details {
    margin-bottom: 64px;
}

.service-details .details-head-title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.8;
    color: var(--up-orange);
    text-align: center;
    margin-bottom: 48px;
}

.service-details .details-body {
    display: flex;
}

.service-details .details-body .details-wrap {
    width: 25%;

}

.service-details .details-body .details-wrap .details-box {
    margin: 0 14px;
    position: relative;
}

.service-details .details-body .details-wrap .details-box .details-img {
    z-index: 801;
}

.service-details .details-body .details-wrap .details-box .details-foreground {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 802;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.service-details .details-body .details-wrap .details-box .details-content {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 803;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 32px;
}

.service-details .details-body .details-wrap .details-box .details-content .details-title {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.8;
}

.service-details .details-body .details-wrap .details-box .details-content .details-subTitle {
    font-size: 16px;
    line-height: 1.8;
}

@media screen and (max-width:992px) {
    .service-box .big-wrap {
        padding: 100px 0;
    }

    .service-details .details-body {
        flex-wrap: wrap;
    }

    .service-details .details-body .details-wrap {
        width: 50%;
    }

    .service-details .details-body .details-wrap .details-box {
        margin: 14px;
    }

    .service-details {
        margin: 0;
    }
}

@media screen and (max-width:600px) {
    .service-details .details-body .details-wrap .details-box .details-content .details-title {
        font-size: 22px;
    }

    .service-box .big-wrap .big-text i {
        display: none;
    }

    .service-box .big-wrap .big-text p {
        margin: 14px;
    }

}

@media screen and (max-width:500px) {
    .service-details .details-body .details-wrap .details-box .details-content .details-subTitle {
        font-size: 14px;
    }
}

@media screen and (max-width:425px) {
    .service-details .details-body .details-wrap {
        width: 100%;
    }

    .service-box .big-wrap .big-text p {
        font-size: 18px;
    }
}

@media screen and (max-width:375px) {
    .service-details .details-head-title {
        font-size: 24px;
    }
}

@media screen and (max-width:320px) {
    .service-box .big-wrap .big-text p {
        font-size: 16px;
    }

    .service-details .details-head-title {
        font-size: 20px;
    }
}